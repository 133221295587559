<template>
  <div>
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>جزئیات آزمایش</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-dialog v-model="confirmDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span><h3>تایید آزمایش</h3></span>
          </v-card-title>
          <v-card-text class="text-right">
            <h4 class="common-text">
              آیا از تایید آزمایش
              {{ labStatusData.labTermNumber }}
              مطمئن هستید ؟
            </h4>
            <h6 class="red--text pt-4">
              <v-icon color="red">mdi-alert</v-icon>
              <span>در صورت تایید نهایی، پاسخ آزمایش قابل تغییر نمی‌باشد.</span>
            </h6>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary-btn" outlined @click="confirmLab">
              تایید
            </v-btn>
            <v-btn class="red-btn" outlined @click="confirmDialog = false">
              لغو
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card class="cards pa-6">
        <!-- <v-row style="justify-content: flex-end" v-if="role == 'admin' && labStatusData.labStatus != 'جدید'">
          <v-btn
            :disabled="Busy"
            style="float: left"
            class="secondary-btn mb-2 me-2 ms-2"
            @click="$refs.tableExcel.$el.click()"
            >دریافت اکسل
          </v-btn>
          <vue-excel-xlsx
            v-show="false"
            ref="tableExcel"
            :data="Items"
            :columns="
              excelFields.map((x) => {
                return {
                  label: x.label,
                  field: x.key,
                };
              })
            "
            :filename="'جزییات آزمایش شماره  ' + labStatusData.labTermNumber"
            :sheetname="currentDate"
          >
            دریافت اکسل
          </vue-excel-xlsx>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="common-text">
              <span class="primary--text" style="font-weight: bold">
                شماره پذیرش:
              </span>
              {{ labStatusData.labTermNumber }}
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="common-text">
              <span class="primary--text" style="font-weight: bold">
                نام بیمار:
              </span>
              {{ labStatusData.patientName }}
              <span class="ms-1 me-1 hover-link" v-if="role == 'admin'">
                <router-link
                  :to="'/admin/editPatient/' + labStatusData.patientId"
                >
                  (ویرایش بیمار)
                </router-link>
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="common-text">
              <span class="primary--text" style="font-weight: bold">
                وضعیت پرداخت:
              </span>
              {{ labStatusData.labPaymentStatus }}
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="common-text">
              <span class="primary--text" style="font-weight: bold">
                مبلغ پرداخت شده:
              </span>
              {{ Number(labStatusData.paidCost).toLocaleString() }} ریال
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="common-text">
              <span class="primary--text" style="font-weight: bold">
                وضعیت آزمایش:
              </span>
              {{ labStatusData.labStatus }}
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="common-text" v-if="!labStatusData.isOut">
              <span class="primary--text" style="font-weight: bold">
                دکتر:
              </span>
              {{ labStatusData.docName }}
            </div>
            <div class="common-text" v-else>
              <span class="primary--text" style="font-weight: bold">
                دکتر:
              </span>
              {{ labStatusData.outDoctor }}
              (دکتر خارج از درمانگاه)
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="common-text">
              <span class="primary--text" style="font-weight: bold">
                بیمه:
              </span>
              {{
                labStatusData.insuranceName ? labStatusData.insuranceName : "-"
              }}
            </div>
          </v-col>
        </v-row>
        <div class="mt-4">
          <div v-if="labStatusData.labStatus != 'جدید'">
            <div v-for="(item, index) in Items" :key="index">
              <h5 class="mb-4">{{ item.section }}:</h5>
              <b-table
                small
                responsive
                show-empty
                :fields="Fields"
                :items="item.parts"
                class="mb-4"
                empty-text="رکوردی برای نمایش وجود ندارد"
                empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                :busy="Busy"
              >
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template v-slot:cell(labDescription)="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    v-if="deviceType == 'mobile'"
                  >
                    <v-btn
                      v-if="data.value.length > 20"
                      class="primary-btn py-3"
                      @click="
                        descDialog = true;
                        selected = data.item;
                      "
                      >توضیحات</v-btn
                    >
                    <span v-else>{{ data.value }}</span>
                  </div>
                  <div
                    style="text-align: center; vertical-align: middle"
                    v-else
                  >
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(normalRange)="data">
                  <div
                    style="
                      text-align: center;
                      vertical-align: middle;
                      direction: ltr !important;
                    "
                    dir="ltr"
                  >
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(labUnit)="data">
                  <div
                    style="
                      text-align: center;
                      vertical-align: middle;
                      direction: ltr !important;
                    "
                    dir="ltr"
                  >
                    {{ data.value }}
                  </div>
                </template>
                <div slot="table-busy" class="text-center primary--text my-2">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                </div>
              </b-table>
              <hr class="mb-8" />
            </div>
          </div>
          <div v-else>
            <hr />
            <h5 class="text-center mt-4">پاسخ آزمایش هنوز آماده نشده است</h5>
          </div>
          <v-row>
            <v-col> </v-col>
            <!-- <v-col class="text-left" v-if="role == 'labReception'">
              <v-btn
                class="primary-btn pe-5 ps-5"
                @click="openConfirmDialog()"
                v-if="labStatusData.labStatus == 'پاسخ اولیه'"
              >
                <h6 class="mt-1">
                  <v-icon left>check_circle</v-icon>
                  تایید
                </h6>
              </v-btn></v-col
            > -->
          </v-row>
        </div>
      </v-card>
    </v-col>
    <v-dialog v-model="descDialog" width="600px">
      <v-card class="pa-5 cards">
        <v-card-title>
          <h4 class="primary--text">توضیحات</h4>
        </v-card-title>
        <v-card-text>
          <p
            class="info-text text-right text-justify"
            style="line-height: 30px"
          >
            {{ selected.labDescription }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn @click="descDialog = false" class="red-btn">
            <v-icon>close</v-icon>
            <span> بستن </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
export default {
  data() {
    return {
      labStatusData: {},
      Fields: [
        { key: "subject", label: "عنوان" },
        { key: "labResult", label: "جواب" },
        { key: "normalRange", label: " رنج نرمال" },
        { key: "labUnit", label: "واحد " },
        { key: "labDescription", label: "توضیحات " },
      ],
      Items: [],
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      confirmDialog: false,
      role: window.localStorage.role,
      excelFields: [
        { key: "subject", label: "عنوان" },
        { key: "labResult", label: "جواب" },
        { key: "normalRange", label: " رنج نرمال" },
        { key: "labUnit", label: "واحد " },
        { key: "labSection", label: "بخش " },
        { key: "date", label: "تاریخ " },
      ],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      descDialog: false,
      selected: {},
    };
  },
  methods: {
    getLabDetailsInfo() {
      this.Busy = true;
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/id",
          {
            labRequestId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          this.vLoading = false;
          if (res.status == 200) {
            this.labStatusData = res.data.labStatusData;
            this.Items = res.data.sections;
            this.TotalRows = this.Items.length;
          } else {
            this.vLoading = false;
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    openConfirmDialog() {
      this.confirmDialog = true;
    },
    confirmLab() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/approveLabRequestId",
          {
            labRequestId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.confirmDialog = false;
            this.getLabDetailsInfo();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.confirmDialog = false;
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
          this.confirmDialog = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    this.getLabDetailsInfo();
  },
};
</script>
